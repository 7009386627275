<template>
  <div class="church-list template-1">
    <header class="page-header container">
      <h1>{{ translations.tcPresenters }}</h1>
    </header>
    <security-banner v-if="!allowPage" :i18n="translations.components"></security-banner>
    <div v-if="allowPage">
      <camp-selector
        @camp_changed="page_load($event)"
        :tooltip="translations.tcCurrentHome"
        :i18n="translations.components"
      ></camp-selector>
      <data-table
        :fields="dataTableFields"
        :items="items"
        :hiddenItems="hiddenItems"
        :addItems="addItems"
        :includeAddButton="iCanSee(secured_church_presenter_add_controls.add_church_presenter_button)"
        @addPresenter="addPresenter"
        :searchTerm="`presentersTerm`"
        :csvUrl="churchPresentersByCampAsCSVUrl"
        :pdfUrl="churchPresentersByCampAsPDFUrl"
        :reportName="reportName"
        :i18n="translations.components"
      ></data-table>
      <footer class="page-footer"></footer>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import CampSelect from '@/components/CampSelect.vue'
import DataTable from '@/components/DataTable.vue'
import SecurityBanner from '@/components/SecurityBanner'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'presenters',
  mixins: [translationMixin],
  data() {
    return {
      addItems: {
        display: true,
        text: 'Add Presenter',
        action: 'addPresenter',
      },
      dataTableFields: [],
      hiddenItems: {
        display: false,
      },
      items: [],
      reportName: 'Presenters',
      secured_church_presenter_add_controls: {
        add_church_presenter_button: '673382d2-3a00-4d7f-b72d-c5f305669cb7',
      },
      translations: {},
    }
  },
  async created() {
    try {
      await Promise.all([
        this.setLoadingStatus(true),
        this.getTranslations(),
        this.getComponentTranslations('data-table', 'security-banner', 'camp-select'),
      ]).then((results) => {
        const componentTranslations = results[2]
        this.$set(this.translations, 'components', componentTranslations)
        this.loadTranslations()
        this.page_load()
      })
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
  methods: {
    ...mapActions({
      loadChurchPresentersReportUrls: 'churchMinistry/loadChurchPresentersReportUrls',
      loadPresenters: 'churchMinistry/loadQualifiedPresenters',
      setLoadingStatus: 'menu/setLoadingStatus',
    }),
    async page_load(evt) {
      if (!evt) {
        // execute only if evt is undefined
        try {
          if (!this.loading) this.setLoadingStatus(true)
          await Promise.all([this.loadPresenters(), this.loadChurchPresentersReportUrls()]).then(() => {
            this.loadDataTableItems()
          })
        } catch (e) {
          console.error('Error in Presenters.vue, page_load()', e)
        } finally {
          this.setLoadingStatus(false)
        }
      }
    },
    addPresenter() {
      this.$router.push('/programs/cm/churchProfile/add-church-presenter')
    },
    loadTranslations() {
      this.addItems.text = this.translations.tcAddItems
      this.loadDataTableFields()
    },
    loadDataTableFields() {
      this.dataTableFields = [
        { key: 'speaker', label: `${this.translations.tcSpeaker}`, sortable: true },
        {
          key: 'date_qualified',
          label: `${this.translations.tcDateQualified}`,
          sortable: true,
        },
        {
          key: 'Media',
          label: `${this.translations.tcMedia}`,
          sortable: true,
          cToolTip: `${this.translations.tcToolTip}`,
        },
        { key: 'History', label: `${this.translations.tcComments}`, sortable: true },
        { key: 'history_ind_key', label: ``, sortable: false },
      ]
    },
    loadDataTableItems() {
      if (!this.translations.components || !this.presenters) return false

      this.items = this.presenters.map((el) => {
        return {
          ind_key: el.ind_key,
          speaker: el.name,
          date_qualified: el.qualified_date && el.qualified_date.substring(0, 10),
          Media: !!el.presentation_media_flag ? this.translations.tcYes : this.translations.tcNo,
          History: el.ixa_note,
          history_ind_key: el.ind_key,
        }
      })
    },
  },
  computed: {
    ...mapGetters({
      churchPresentersByCampAsCSVUrl: 'churchMinistry/datalist1CSVUrl',
      churchPresentersByCampAsPDFUrl: 'churchMinistry/datalist1PDFUrl',
      iCanSee: 'user/iCanSee',
      isInMenu: 'menu/isInMenu',
      loading: 'menu/loadingStatus',
      prefCulture: 'user/userPreferredCulture',
      presenters: 'churchMinistry/presenters',
    }),
    allowPage() {
      return this.isInMenu(this.$route.path)
    },
  },
  components: {
    campSelector: CampSelect,
    dataTable: DataTable,
    securityBanner: SecurityBanner,
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';
@import '@/styles/views/ChurchList.scss';
</style>
